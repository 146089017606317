import { lazy, Suspense, useEffect } from "react";
import "./App.scss";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Loader from "./components/shared/Loader/Loader";
import Aos from "aos";
import "aos/dist/aos.css";
import { translate } from "./translation/translate";
import { useSelector } from "react-redux";
const Home = lazy(() => import("./pages/Home/Home"));
const About = lazy(() => import("./pages/About/Home"));
const OurTeam = lazy(() => import("./pages/OurTeam/Home"));
const Careers = lazy(() => import("./pages/Careers/Home"));
const Partners = lazy(() => import("./pages/Partners/Home"));
const Clients = lazy(() => import("./pages/Clients/Home"));
const LatestNews = lazy(() => import("./pages/LatestNews/Home"));
const Blog = lazy(() => import("./pages/Blog/Home"));
const KosovaAcademy = lazy(() => import("./pages/KosovaAcademy/Home"));
const Services = lazy(() => import("./pages/Services/Home"));
const SoftwareDevelopment = lazy(() =>
  import("./pages/SoftwareDevelopment/Home")
);
const TestingQA = lazy(() => import("./pages/TestingQA/Home"));
const ApplicationServices = lazy(() =>
  import("./pages/ApplicationServices/Home")
);
const EmployeeTime = lazy(() => import("./pages/EmployeeTime/Home"));
const CaseStudies = lazy(() => import("./pages/CaseStudies/Home"));
const LearningPortal = lazy(() => import("./pages/LearningPortal/Home"));
const ExploringAsset = lazy(() => import("./pages/ExploringAsset/Home"));
const LeaveManagement = lazy(() => import("./pages/LeaveManagement/Home"));
const SmartBuilding = lazy(() => import("./pages/SmartBuilding/Home"));
const SoftwareStrategic = lazy(() => import("./pages/SoftwareStrategic/Home"));
const SpaceManagement = lazy(() => import("./pages/SpaceManagement/Home"));
const UIUXDesign = lazy(() => import("./pages/UIUXDesign/Home"));
const UserExperience = lazy(() => import("./pages/UserExperience/Home"));
const UserInterface = lazy(() => import("./pages/UserInterface/Home"));
const InfrastructureServices = lazy(() =>
  import("./pages/InfrastructureServices/Home")
);
const UIUXInternship = lazy(() => import("./pages/UIUXInternship/Home"));
const UIUXBeginner = lazy(() => import("./pages/UIUXBeginner/Home"));
const UIUXInternshipNew = lazy(() => import("./pages/UIUXInternshipNew/Home"));
const ManagedServices = lazy(() => import("./pages/ManagedServices/Home"));
const DataAnalytics = lazy(() => import("./pages/DataAnalytics/Home"));
const Operations = lazy(() => import("./pages/Operations/Home"));
const Market = lazy(() => import("./pages/Market/Home"));
const Humans = lazy(() => import("./pages/Human/Home"));

function App() {
  const language = useSelector((state) => state.language.language);
  useEffect(() => {
    Aos.init({
      duration: 1250,
      once: true,
    });
  }, []);

  return (
    <div>
      <Router>
        <Suspense fallback={<Loader />}>
          <IntlProvider
            locale={language}
            formats={{ number: "en" }}
            messages={translate[language]}
          >
            <ScrollToTop />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/team" element={<OurTeam />} />
              <Route exact path="/careers" element={<Careers />} />
              <Route exact path="/partners" element={<Partners />} />
              <Route exact path="/clients" element={<Clients />} />
              <Route exact path="/latest-news" element={<LatestNews />} />
              <Route exact path="/blog" element={<Blog />} />
              <Route exact path="/kosova-academy" element={<KosovaAcademy />} />
              <Route exact path="/services" element={<Services />} />
              <Route
                exact
                path="/software-development"
                element={<SoftwareDevelopment />}
              />
              <Route exact path="/testing-qa" element={<TestingQA />} />
              <Route
                exact
                path="/application-services"
                element={<ApplicationServices />}
              />
              <Route
                exact
                path="/blog/employee-time"
                element={<EmployeeTime />}
              />
              <Route exact path="/case-studies" element={<CaseStudies />} />
              <Route
                exact
                path="/learning-portal"
                element={<LearningPortal />}
              />
              <Route
                exact
                path="/blog/exploring-asset"
                element={<ExploringAsset />}
              />
              <Route
                exact
                path="/blog/leave-management"
                element={<LeaveManagement />}
              />
              <Route
                exact
                path="/blog/smart-building"
                element={<SmartBuilding />}
              />
              <Route
                exact
                path="/blog/software-strategic"
                element={<SoftwareStrategic />}
              />
              <Route
                exact
                path="/blog/space-management"
                element={<SpaceManagement />}
              />
              <Route exact path="/uiux-design" element={<UIUXDesign />} />
              <Route
                exact
                path="/uiux-design/user-experience"
                element={<UserExperience />}
              />
              <Route
                exact
                path="/uiux-design/user-interface"
                element={<UserInterface />}
              />
              <Route
                exact
                path="/infrastructure-services"
                element={<InfrastructureServices />}
              />
              {/* <Route exact path="/uiux-internship2" element={<UIUXInternship />} /> */}
              <Route exact path="/uiux-beginner" element={<UIUXBeginner />} />
              <Route
                exact
                path="/uiux-internship"
                element={<UIUXInternshipNew />}
              />
              <Route
                exact
                path="/managed-it-services"
                element={<ManagedServices />}
              />
              <Route exact path="/data-analytics" element={<DataAnalytics />} />
              <Route
                exact
                path="/operation-managment"
                element={<Operations />}
              />
              <Route exact path="/Market" element={<Market />} />

              <Route exact path="/Humans" element={<Humans />} />
            </Routes>
          </IntlProvider>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
